import * as Sentry from "@sentry/react";
import { init as initWebOSDeviceInfo } from "../helper/platforms/webos";
import { init as initVestelDeviceInfo } from "../helper/platforms/vestel";
import { init as initArcelikDeviceInfo } from "../helper/platforms/arcelik";
import { init as initTizenDeviceInfo } from "../helper/platforms/tizen";

export function setDeviceInformations(){
    var p = getPlatform();
    let platformName = p.name;

    window.platform = p.name; // webos/arcelik/tizen/vestel
    window.version = p.version; // webos ve tizen için sürüm bilgisi
    window.brand = p.brand; // marka adı
    window.chromiumVersion = getChromiumVersion();

    // CSS için body'e platform adı class olarak ekleniyor.
    if(document.documentElement.classList.contains(platformName) === false){
        document.documentElement.classList.add(platformName);
    }

    if(platformName === "webos"){
        initWebOSDeviceInfo(); // not: asenkron çalıştığından bazı bilgiler geç gelebilir.
        setTimeout(function(){
            window.platform !== undefined && Sentry.setTag("platform", window.platform);
            window.brand !== undefined && Sentry.setTag("brand", window.brand);
            window.version !== undefined && Sentry.setTag("version", String(window.version));
        },1000);
    }
    else if(platformName === "vestel"){
        initVestelDeviceInfo();
    }
    else if(platformName === "vidaa"){
        //initVidaaDeviceInfo();
    }
    else if(platformName === "arcelik"){
        initArcelikDeviceInfo(); // not: asenkron çalıştığından bazı bilgiler geç gelebilir.
    }
    else if(platformName === "tizen"){
        initTizenDeviceInfo();
    }
}

// webos/arcelik/tizen/vestel gruplarından hangisi olduğunu + marka adı + (varsa)işletim sistemi versiyonunu bulur.
export function getPlatform() {
	var userAgent = navigator.userAgent;
	var name = "unknown";
    var brand = "unknown";
	var version = "unknown";

    // ****** İlerde lazım olabilecek kontroller
    // userAgent.includes("Tizen") && e.includes("SMART-TV") ? "Samsung" 
    // userAgent.includes("Web0S") ? "LG" 
    // userAgent.includes("AFTT") || e.includes("AFTM") ? "Amazon Fire Stick" 
    // userAgent.includes("Philips") ? "Philips" 
    // userAgent.includes("Roku") ? "Roku TV"
    // userAgent.includes("Apple TV") || e.includes("AppleTV") ? "Apple TV"
    // userAgent.includes("Sony") ? "Sony"
    // userAgent.includes("Macintosh") ? "Macintosh"
    // userAgent.includes("VIZIO") ? "Vizio" 
    // userAgent.includes("Xbox") ? "Xbox"
    // userAgent.includes("playstation") ? "Playstation"
    // userAgent.includes("hisense") || e.includes("vidaa") ? "Hisense"
    // userAgent.includes("panasonic") ? "Panasonic"



    // Arçelik user-agent
    // Mozilla/5.0 (Linux armv9; U; en;) AppleWebKit/537.36 (Grundig;visionos;NX;03.023.00;) CE-HTML/1.0 NETRANGEMMH HbbTV/2.0 (SRAF;Arcelik_Gen3;RC_V2;Arcelik;NX;NXGRMR.-.-.-.-.V03.023.00;tr-TR;Wireless;prod;)

    // Vestel user-agent
    // Mozilla/5.0 (Linux armv7l) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/53.0.2785.143 Safari/537.36 OPR/40.0.2207.0 OMI/4.9.0.237.DOM3.194 Model/Vestel-MB211 VSTVB MB200 HbbTV/1.2.1 (; VORTEX; MB211; 0.49.4.0; _TV_NT72563_2017;) SmartTvA/3.0.0

    // webOS için örnek user-agentlar:
    // https://webostv.developer.lge.com/discover/specifications/web-engine/

    // tizen user-agent'lar:
    // https://developer.samsung.com/smarttv/develop/guides/fundamentals/retrieving-platform-information.html
        

    

	// webOS(LG)
	// ref: https://webostv.developer.lge.com/discover/specifications/web-engine/
	if (userAgent.indexOf("Web0S") >= 0) {
		name = "webos";
        brand = "LG";

		if (userAgent.indexOf("537.41") >= 0) {
			version = "1.x";
		} else if (userAgent.indexOf("538.2") >= 0) {
			version = "2.x";
		} else if (userAgent.indexOf("537.36") >= 0) {
			// 3,4,5,6, 22 kendi içinde farklı kriterle ayrıştırılıyor.
            if (userAgent.indexOf("87.0.4280.88") >= 0) {
				version = "22";
			} else if (userAgent.indexOf("79.0.3945.79") >= 0) {
				version = "6.x";
			} else if (userAgent.indexOf("68.0.3440.106") >= 0) {
				version = "5.x";
			} else if (userAgent.indexOf("53.0.2785.34") >= 0) {
				version = "4.x";
			} else {
				version = "3.x";
			}
		} else if (userAgent.indexOf("Web0S") >= 0) {
			version = "3.x plus(unknown version)";
		}
	}
	// else if (navigator.userAgent.indexOf('LG NetCast.TV-2011') >= 0) {
	// 	// lg-2011
	// }
	// else if (navigator.userAgent.indexOf('LG NetCast.TV-2012') >= 0) {
	// 	// lg-2012
	// }
	// else if (navigator.userAgent.indexOf('LG NetCast.TV') >= 0) {
	// 	// lg-2013
	// }
	// else if (navigator.userAgent.indexOf('LG SimpleSmart.TV-2016') >= 0) {
	// 	// lg-2016
	// }

	// Tizen (Samsung)
    // ref: https://developer.samsung.com/smarttv/develop/guides/fundamentals/retrieving-platform-information.html
	// ref: https://developer.samsung.com/smarttv/develop/specifications/tv-model-groups.html
	else if (userAgent.indexOf("Tizen") >= 0) {
		name = "tizen";
        brand = "Samsung";

		if (userAgent.indexOf("Tizen 2.2") >= 0) {
			version = "2015";
		} else if (
			userAgent.indexOf("Tizen 2.4.0") >= 0 &&
			userAgent.indexOf("AppleWebKit") >= 0
		) {
			version = "2016";
		} else if (userAgent.indexOf("Tizen 3.0") >= 0) {
			version = "2017";
		} else if (userAgent.indexOf("Tizen 4.0") >= 0) {
			version = "2018";
		} else if (userAgent.indexOf("Tizen 5.0") >= 0) {
			version = "2019";
		} else if (userAgent.indexOf("Tizen 5.5") >= 0) {
			version = "2020";
		} else if (userAgent.indexOf("Tizen 6") >= 0) {
			version = "2021";
		} else if (userAgent.indexOf("Tizen 6.5") >= 0) {
			version = "2022";
		} else if (userAgent.indexOf("Tizen 7.0") >= 0) {
			version = "2023";
		}
	}

    // HISENSE/VIDAA
    // ref: https://www.hisense.com.tr/destek/televizyonlar/
    // ref: https://www.vidaa.com/tr/
    // ref: https://partner-doc.vidaa.com/vdocs/development/open-capacity.html#user-agent
    else if (
		userAgent.indexOf("Hisense") >= 0 ||
		userAgent.indexOf("VIDAA") >= 0
	) {
		name = "vidaa";
        
        if (userAgent.indexOf("Hisense") >= 0) {
            brand = "Hisense";
        } else if (userAgent.indexOf("Toshiba") >= 0) {
            brand = "Toshiba";
        } else if (userAgent.indexOf("Loewe") >= 0) {
            brand = "Loewe";
        } else if (userAgent.indexOf("Vestel") >= 0) {
            brand = "Vestel";
        } else if (userAgent.indexOf("We by Loewe") >= 0) {
            brand = "We by Loewe";
        } else if (userAgent.indexOf("Newsan") >= 0) {
            brand = "Newsan";
        } else if (userAgent.indexOf("BGH") >= 0) {
            brand = "BGH";
        } else if (userAgent.indexOf("Element") >= 0) {
            brand = "Element";
        } else if (userAgent.indexOf("Schneider") >= 0) {
            brand = "Schneider";
        } else if (userAgent.indexOf("Nordmende") >= 0) {
            brand = "Nordmende";
        } else if (userAgent.indexOf("Shinon") >= 0) {
            brand = "Shinon";
        } else if (userAgent.indexOf("Vortex") >= 0) {
            brand = "Vortex";
        } else if (userAgent.indexOf("AKAI") >= 0) {
            brand = "AKAI";
        } else if (userAgent.indexOf("NIKAI") >= 0) {
            brand = "NIKAI";
        } else if (userAgent.indexOf("BRUHM") >= 0) {
            brand = "BRUHM";
        } else if (userAgent.indexOf("General Gold") >= 0) {
            brand = "General Gold";
        } else if (userAgent.indexOf("JVC") >= 0) {
            brand = "JVC";
        } else if (userAgent.indexOf("Kendo") >= 0) {
            brand = "Kendo";
        } else if (userAgent.indexOf("KONKA") >= 0) {
            brand = "KONKA";
        } else if (userAgent.indexOf("KTC") >= 0) {
            brand = "KTC";
        } else if (userAgent.indexOf("Manta") >= 0) {
            brand = "Manta";
        }
    }

	// VESTEL
	// ref: http://vstlsrv.com/swtest/smarttv/index.html#useragent
	else if (
		userAgent.indexOf("Vestel") >= 0 ||
		userAgent.indexOf("VESTEL") >= 0 ||
		userAgent.indexOf("VSTVB") >= 0 ||
        userAgent.indexOf("PANASONIC") >= 0 ||
        userAgent.indexOf("TOSHIBA") >= 0 ||
        userAgent.indexOf("GOGEN") >= 0 ||
        userAgent.indexOf("HYUNDAI") >= 0 
	) {
		name = "vestel";
        brand = "Vestel";

        if (
            userAgent.indexOf("Vestel") >= 0 ||
            userAgent.indexOf("VESTEL") >= 0 ||
            userAgent.indexOf("VSTVB") >= 0
        ) {
            brand = "Vestel";
        }
        else if (userAgent.indexOf("PANASONIC") >= 0) {
            brand = "Panasonic";
        }
        else if (userAgent.indexOf("TOSHIBA") >= 0) {
            brand = "Toshiba";
        }
        else if (userAgent.indexOf("GOGEN") >= 0) {
            brand = "Gogen";
        }
        else if (userAgent.indexOf("HYUNDAI") >= 0) {
            brand = "Hyundai";
        }

        // if (userAgent.indexOf("MB230") >= 0) {
        //     version = "1.x";
        // }
	}


    // Zeasn
    // ref: https://partner.zeasn.com/partnerportal/DeviceMapping/index.jhtml
    // ref : https://partner.zeasn.com/partnerportal/Capbilities/index.jhtml
    else if (userAgent.indexOf("WhaleTV") >= 0){
        name = "zeasn";
        brand = "unknown";

        if (userAgent.indexOf("TCL-") >= 0 ||
            userAgent.indexOf("Iserver-") >= 0 ||
            userAgent.indexOf("NT72671D") >= 0 
        ) {
            brand = "TCL";
        }
        else if (userAgent.indexOf("Haier-") >= 0) {
            brand = "Haier";
        }
        else if (userAgent.indexOf("MT") >= 0 ||
            userAgent.indexOf("NV") >= 0) {
            brand = "Philips"; // AOC olma ihtimali de mevcut, model adı ile ayrıştırmak gerekebilir.

            if(userAgent.indexOf("MT5596L") >= 0){
                window.modelName = "MT5596L";
            }
            else if(userAgent.indexOf("MT5582") >= 0){
                window.modelName = "MT5582";
            }
            else if(userAgent.indexOf("MT5593 U+") >= 0){
                window.modelName = "MT5593 U+";
            }
            else if(userAgent.indexOf("MT5593") >= 0){
                window.modelName = "MT5593";
            }
            else if(userAgent.indexOf("MT5800") >= 0){
                window.modelName = "MT5800";
            }
            else if(userAgent.indexOf("MT5802") >= 0){
                window.modelName = "MT5802";
            }
            else if(userAgent.indexOf("MT5596") >= 0){
                window.modelName = "MT5596";
            }
            else if(userAgent.indexOf("MT5802") >= 0){
                window.modelName = "MT5802";
            }
            else if(userAgent.indexOf("MT5800") >= 0){
                window.modelName = "MT5800";
            }
            else if(userAgent.indexOf("MT5596") >= 0){
                window.modelName = "MT5596";
            }
            else if(userAgent.indexOf("MT5806") >= 0){
                window.modelName = "MT5806";
            }
            else if(userAgent.indexOf("MT5599") >= 0){
                window.modelName = "MT5599";
            }
            else if(userAgent.indexOf("MT9288") >= 0){
                window.modelName = "MT9288";
            }
            else if(userAgent.indexOf("MT5806") >= 0){
                window.modelName = "MT5806";
            }
            else if(userAgent.indexOf("MT5599") >= 0){
                window.modelName = "MT5599";
            }
            else if(userAgent.indexOf("NVT671") >= 0){
                window.modelName = "NVT671";
            }
            else if(userAgent.indexOf("MT9970") >= 0){
                window.modelName = "MT9970";
            }else if(userAgent.indexOf("NVT671") >= 0){
                window.modelName = "NVT671";
            }
            else if(userAgent.indexOf("NVT690") >= 0){
                window.modelName = "NVT690";
            }
        }
    }

	// ARÇELİK
	// ref: https://github.com/BluPoint-io/smarttv-device-recognizer/blob/0b0f31d514e2ba8e8e48edc205a65ec4f2d6777a/src/statics/platforms.js
	else if (userAgent.indexOf("Grundig") >= 0) {
		name = "arcelik";
        brand = "Grundig";
	} else if (userAgent.indexOf("Beko") >= 0) {
		name = "arcelik";
        brand = "Beko";
	} else if (userAgent.indexOf("Altus") >= 0) {
		name = "arcelik";
        brand = "Altus";
	} else if (userAgent.indexOf("Arcelik") >= 0) {
		name = "arcelik";
        brand = "Arçelik";
	}



    
	// webos,tizen,arcelik,vestel değilse varsayılan olarak "web" değeri atanır.
	if (name === "" || name === "unknown") {
		name = "web";
        brand = "web";
	}

	// body'e class olarak platform adı ekleniyor. (webos,tizen,vestel,arcelik)
	// if (name != "" && document.body.classList.contains(name) == false) {
	// 	document.body.classList.add(name);
	// }

    return {
        "name": name,
        "brand": brand,
        "version": version
    }
}

export function getChromiumVersion(){
    let version = "";
    try{
        var userAgent = navigator.userAgent;
        if (userAgent.indexOf("Chrome/") >= 0) {
            //eslint-disable-next-line
            version = parseInt((/Chrome\/([0-9]+)/.exec(userAgent)||[,0])[1]);
        }
    }
    catch{
        version = "unknown";
    }
    return version;
}

// ekran çözünürlüğünü döndürür. width ve height
export function getScreenSize() {
    // ref: https://docs.signageos.io/hc/en-us/articles/4405238230418-Universal-Screen-Width-and-Height-in-Javascript
	var e, t;
	if ("number" == typeof window.innerWidth) {
		e = window.innerWidth;
		t = window.innerHeight;
	} else {
		var o = document.documentElement || document.body;
		t = o.clientHeight || o.offsetHeight;
		e = o.clientWidth || o.offsetWidth;
	}
	return { width: e, height: t };
}