import React, { useEffect } from "react";
import GeneralProvider from "../provider/generalProvider";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar";
import { withFocusable } from "@noriginmedia/react-spatial-navigation";
import MainWrapper from "../components/mainWrapper";
import withDirectionFocusable from "../components/withDirectionFocusable";
import BackKeyHandler from "../components/backKeyHandler";

const SidebarFocusable = withFocusable({ trackChildren: true })(Sidebar);
const MainWrapperFocusable = withFocusable({ trackChildren: true })(MainWrapper);
const OutletFocusable = withFocusable()(Outlet);
const BackKeyHandlerFocusable = withDirectionFocusable(BackKeyHandler);

export default function MainLayout(props) {
	useEffect(() => {
		props.setFocus("Outlet");

        // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<GeneralProvider>
			<MainWrapperFocusable focusKey={"MainWrapper"}>
                {
                    (window.platform === "vestel" || window.platform === "vidaa" || window.platform === "tizen" || window.platform === "webos") &&
                    <BackKeyHandlerFocusable 
                        focusKey={"ExitConfirmationFocusable"}
                        backFocus={"WatchNowButton"} />
                }
				<span id="logo"></span>
				<OutletFocusable focusKey={"Outlet"} />
				<SidebarFocusable focusKey={"Sidebar"} />
			</MainWrapperFocusable>
		</GeneralProvider>
	);
}
