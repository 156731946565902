import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "../components/snackbar";
import Cookie from "universal-cookie";
import Button from "../components/Button/button";
import Input from "../components/Input/input";
import LoadingFullscreen from "../components/loadingFullscreen";
import withDirectionFocusable from "../components/withDirectionFocusable";
import BackKeyHandler from "../components/backKeyHandler";
import * as Constants from "../constants/constants";
import { getPlatform } from "../helper/platform";
import {
	login,
	getSubscriptionDetails
} from "../services/gainAPI";
import {
	pageView,
	login as loginEvent,
	loginFailed as loginFailedEvent
} from "../services/gtm";
// import 'dayjs/locale/tr';

const ButtonFocusable = withDirectionFocusable(Button);
const InputFocusable = withDirectionFocusable(Input);
const BackKeyHandlerFocusable = withDirectionFocusable(BackKeyHandler);

export default function Login(props) {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [snackbar, setSnackbar] = useState(null);
	const [loading, setLoading] = useState(null);

	useEffect(() => {
		document.title = "Giriş yapın";
		pageView();
		props.setFocus("Email");

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		function keyDown(e) {
			// console.log(e.keyCode);
			// sanal klavyede enter tuşuna basıldığında bir sonraki alana focuslanması sağlanır.
			if ((e.keyCode === 13 || e.keyCode == window.VK_ENTER) && getPlatform().name !== "arcelik") {
				if (document.activeElement.getAttribute("id") === "email") {
					document.getElementById("password").focus();
					props.setFocus("Password");
				} else if (
					document.activeElement.getAttribute("id") === "password" &&
					document.querySelectorAll(".password-switcher.selected").length === 0
				) {
					document.getElementById("passwordToggle").focus();
					props.setFocus("PasswordVisibility");
				}
			}
		}

		window.addEventListener("keydown", keyDown);
		return () => {
			window.removeEventListener("keydown", keyDown);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function validateForm() {
		var flag = true;

		//eslint-disable-next-line
		var mailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (email.length === 0 || password.length === 0) {
			window.google_tag_manager && loginFailedEvent("E-posta ve şifrenizi girin.");
			setSnackbar({
				show: true,
				message: "E-posta ve şifrenizi girin.",
				cssClassName: "snackbar-validation",
			});
			flag = false;
		} else if (!email.match(mailRegex)) {
			window.google_tag_manager && loginFailedEvent("E-posta adresinizi hatalı girdiniz.");
			setSnackbar({
				show: true,
				message: "E-posta adresinizi hatalı girdiniz.",
				cssClassName: "snackbar-validation",
			});
			flag = false;
		}

		if (flag) {
			setSnackbar(null);
		}

		return flag;
	}

	async function handleSubmit() {
		if (validateForm()) {
			setLoading(true);
			var result = await login(email, password);

			if (result !== null && result.is_success === true) {
				// ref: https://github.com/reactivestack/cookies/tree/master/packages/universal-cookie
				var cookie = new Cookie();
				cookie.set("uuid", result.uuid, {
					path: "/",
					maxAge: 60 * 60 * 24 * 90,
				}); // 90 gün
				cookie.set("access_token", result.access_token, {
					path: "/",
					maxAge: 60 * 60 * 24 * 90,
				}); // 90 gün
				cookie.set("refresh_token", result.refresh_token, {
					path: "/",
					maxAge: 60 * 60 * 24 * 90,
				}); // 90 gün

				var subscriptionInfo = await getSubscriptionDetails();
				//console.log("subscriptionInfo", subscriptionInfo);
				let lastBillingDate = subscriptionInfo !== null ? subscriptionInfo.lastBillingDate : null;
				window.google_tag_manager && loginEvent(result.uuid, result.created_at, lastBillingDate, result.is_premium);

				setLoading(false);

				if(subscriptionInfo.isPremium != null && subscriptionInfo.isPremium === true)
				{
					navigate("/", { replace: true });
				}
				else{
					navigate("/free-account-warning");
				}
			} else {
				setLoading(false);
				window.google_tag_manager && loginFailedEvent(result.message);

				setSnackbar({
					show: true,
					message: result.message,
					cssClassName: "snackbar-validation",
				});
			}
		}
	}

	function setPasswordVisibility() {
		var passwordInput = document.getElementById("password");
		var toggleButton = document.getElementById("passwordToggle");
		if (passwordInput.type === "password") {
			passwordInput.type = "text";
			toggleButton.classList.add("open-eye");
		} else {
			passwordInput.type = "password";
			toggleButton.classList.remove("open-eye");
		}
	}

	return (
		<div className="wrapper login-wrapper">
			<LoadingFullscreen show={loading} />
			{
				(window.platform === "vestel" || window.platform === "vidaa" || window.platform === "tizen" || window.platform === "webos") &&
				<BackKeyHandlerFocusable
					focusKey={"ExitConfirmationFocusable"}
					backFocus={"Email"} />
			}

			<div className="bg">
				<span className="image"></span>
				<span className="shadow"></span>
			</div>

			<span id="logo-gain"></span>

			<div className="content">
				<div className="text-container">
					<h2>GAİN Hesabına Giriş&nbsp;Yap</h2>
					<h5>
						Daha önce kayıt olmadıysan gain.tv adresine girerek
						üyelik&nbsp;oluştur.
					</h5>

					<ButtonFocusable
						type="button"
						focusKey={"BackToQRButton"}
						id={"BackToQRButton"}
						className="btn btn-medium"
						title={"QR Kod ile Giriş Yap"}
						right={"Email"}
						left={"BackToQRButton"}
						up={"BackToQRButton"}
						down={"BackToQRButton"}
						// disabled={!validateForm()}
						onBecameFocused={(e) => {
								document.getElementById("BackToQRButton").focus();
						}}
						onClick={() => {
							navigate("/qr-login");
						}}
						onEnterPress={() => {
							navigate("/qr-login");
							// props.setFocus("Outlet");
						}}
					/>
				</div>

				<form id="form-login" className="form">
					<div className="form-group">
						<InputFocusable
							focusKey={"Email"}
							type={"text"}
							className={"textbox"}
							id={"email"}
							name={"email"}
							placeholder={"E-posta"}
							down={"Password"}
							// left={()=> {
							//     console.log("left");
							//     // email inputuna focus durumdayken sol tuşa basıldıysa cursoru bir karakter sola taşır.
							//     var input = document.getElementById('email');
							//     if (input.value.length == 0) {
							//         return;
							//     }
							//     input.setAttribute("type", "text");
							//     var currentpos = input.selectionStart;
							//     window.leftmove = currentpos - 1;
							//     setCaretPosition(input, window.leftmove);
							//     input.setAttribute("type", "email");
							// }}
							// right={()=> {
							//     console.log("right");
							//     // email inputuna focus durumdayken sağ tuşa basıldıysa cursoru bir karakter sağa taşır.
							//     var input = document.getElementById('email');
							//     if (input.value.length == 0) {
							//         return;
							//     }
							//     input.setAttribute("type", "text");
							//     var currentpos = input.selectionStart;
							//     window.rightmove = currentpos + 1;
							//     setCaretPosition(input, window.rightmove);
							//     input.setAttribute("type", "email");
							// }}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							// onEnterPress={() => {
							// 	document.getElementById("email").focus();
							// }}
							onBecameFocused={(e) => {
								document.getElementById("email").focus();
								setSnackbar(null);
							}}
						/>
					</div>
					<div className="form-group">
						<InputFocusable
							focusKey={"Password"}
							type={"password"}
							className={"textbox"}
							id={"password"}
							name={"password"}
							placeholder={"Şifre"}
							up={"Email"}
							down={"LoginButton"}
							right={"PasswordVisibility"}
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							onBecameFocused={(e) => {
								document.getElementById("password").focus();
								setSnackbar(null);
							}}
						/>
						<ButtonFocusable
							type="button"
							id={"passwordToggle"}
							focusKey={"PasswordVisibility"}
							className={Constants.BUTTON_PASSWORD_SWITCHER}
							left={"Password"}
							onClick={() => {
								setPasswordVisibility();
							}}
							onEnterPress={() => {
								setPasswordVisibility();
							}}
							onBecameFocused={(e) => {
								document.activeElement.blur(); // password alanı focus durumda kalıyordu, engelleniyor.
								document.getElementById("passwordToggle").focus();
							}}
							icon={
								<>
									<svg
										width="34"
										height="23"
										viewBox="0 0 34 23"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M16.8667 3.06667C22.678 3.06667 27.8607 6.33267 30.3907 11.5C27.8607 16.6673 22.678 19.9333 16.8667 19.9333C11.0553 19.9333 5.87267 16.6673 3.34267 11.5C5.87267 6.33267 11.0553 3.06667 16.8667 3.06667ZM16.8667 0C9.2 0 2.65267 4.76867 0 11.5C2.65267 18.2313 9.2 23 16.8667 23C24.5333 23 31.0807 18.2313 33.7333 11.5C31.0807 4.76867 24.5333 0 16.8667 0ZM16.8667 7.66667C18.9827 7.66667 20.7 9.384 20.7 11.5C20.7 13.616 18.9827 15.3333 16.8667 15.3333C14.7507 15.3333 13.0333 13.616 13.0333 11.5C13.0333 9.384 14.7507 7.66667 16.8667 7.66667ZM16.8667 4.6C13.064 4.6 9.96667 7.69733 9.96667 11.5C9.96667 15.3027 13.064 18.4 16.8667 18.4C20.6693 18.4 23.7667 15.3027 23.7667 11.5C23.7667 7.69733 20.6693 4.6 16.8667 4.6Z"
											fill="white"
										/>
									</svg>
									<svg
										width="34"
										height="29"
										viewBox="0 0 34 29"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M16.658 5.37598C22.3974 5.37598 27.5159 8.60157 30.0146 13.705C29.1212 15.5525 27.8642 17.1426 26.365 18.4298L28.5003 20.565C30.6052 18.7024 32.271 16.3702 33.3159 13.705C30.6961 7.05692 24.2298 2.34726 16.658 2.34726C14.7347 2.34726 12.8872 2.65013 11.1457 3.21044L13.6444 5.70914C14.6287 5.51227 15.6282 5.37598 16.658 5.37598ZM15.0376 7.10235L18.1723 10.2371C19.0355 10.6157 19.7321 11.3123 20.1107 12.1755L23.2454 15.3102C23.3666 14.7953 23.4574 14.2501 23.4574 13.6898C23.4726 9.9342 20.4136 6.89034 16.658 6.89034C16.0977 6.89034 15.5676 6.96606 15.0376 7.10235ZM1.5295 2.15039L5.58799 6.20888C3.11958 8.14726 1.16606 10.7217 0 13.705C2.61984 20.353 9.08616 25.0627 16.658 25.0627C18.9598 25.0627 21.1708 24.6235 23.2 23.8209L28.3791 29L30.5144 26.8648L3.66475 0L1.5295 2.15039ZM12.8872 13.5081L16.8397 17.4606C16.7791 17.4757 16.7185 17.4909 16.658 17.4909C14.5681 17.4909 12.8721 15.7948 12.8721 13.705C12.8721 13.6292 12.8872 13.5838 12.8872 13.5081V13.5081ZM7.73838 8.35927L10.3885 11.0094C10.0402 11.8423 9.84334 12.7509 9.84334 13.705C9.84334 17.4606 12.9024 20.5196 16.658 20.5196C17.612 20.5196 18.5206 20.3227 19.3384 19.9744L20.8225 21.4585C19.4898 21.8219 18.0966 22.0339 16.658 22.0339C10.9185 22.0339 5.8 18.8084 3.30131 13.705C4.36136 11.5394 5.90601 9.75248 7.73838 8.35927Z"
											fill="white"
										/>
									</svg>
								</>
							}
						/>
					</div>
					<div className="form-group">
						<ButtonFocusable
							focusKey={"LoginButton"}
							id={"LoginButton"}
							type="submit"
							className="btn btn-red btn-large btn-full-width"
							title={"Giriş Yap"}
							left={"BackToQRButton"}
							up={"Password"}
							// disabled={!validateForm()}
							onBecameFocused={(e) => {
								document.getElementById("LoginButton").focus();
							}}
							onClick={() => {
								handleSubmit();
							}}
							onEnterPress={() => {
								handleSubmit();
							}}
						/>
					</div>
				</form>

				{snackbar && (
					<Snackbar
						show={snackbar.show}
						cssClassName={snackbar.cssClassName}
						message={snackbar.message}
					/>
				)}
			</div>
		</div>
	);
}