import 'react-app-polyfill/ie11'; // Arçelik için eklendi.
import 'react-app-polyfill/stable'; // ref: https://github.com/facebook/create-react-app/blob/main/packages/react-app-polyfill/README.md
import 'promise-polyfill/src/polyfill';
import "reflect-metadata" // Arçelik için eklendi.
import 'url-search-params-polyfill'; // Arçelik için eklendi. *** Arçelik için
import 'element-closest-polyfill'; // .closest desteklemesi için eklendi.  *** Arçelik için
import 'classlist-polyfill'; // .classList desteklemesi için eklendi.  *** Arçelik için

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Routes, Route } from "react-router-dom";
import "./sass/main.scss";
import Login from "./pages/login";
import Video from "./pages/video";
import Home from "./pages/home";
import Category from "./pages/category";
import Search from "./pages/search";
import List from "./pages/list";
import Title from "./pages/title";
import TitleDetail from "./pages/titleDetail";
import MainLayout from "./layouts/main";
import SettingsLayout from "./layouts/settings";
import SettingsProfile from "./pages/settingsProfile";
import SettingsSignOut from "./pages/settingsSignOut";
import SettingsSubscription from "./pages/settingsSubscription";
import PageNotFound from "./pages/pageNotFound";
import RequireAuth from "./layouts/requireAuth";
import Cookie from "universal-cookie";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import '@procot/webostv/webOSTV';
import { setDeviceInformations } from "./helper/platform";
import {
	initNavigation,
	withFocusable,
} from "@noriginmedia/react-spatial-navigation";
//import reportWebVitals from './reportWebVitals';
import QRLogin from "./pages/QRLogin";
import QRFreeAccountWarning from "./pages/QRFreeAccountWarning";
import QRTimeoutWarning from "./pages/QRTimeoutWarning";
import QRTooManyRequestWarning from "./pages/QRTooManyRequestWarning";
import RedirectToVideo from "./pages/RedirectToVideo";

initNavigation({
	debug: false,
	visualDebug: false,
});

const MainLayoutFocusable = withFocusable()(MainLayout);
const SettingsLayoutFocusable = withFocusable()(SettingsLayout);
const HomeFocusable = withFocusable()(Home);
const TitleFocusable = withFocusable()(Title);
const TitleDetailFocusable = withFocusable()(TitleDetail);
const SearchFocusable = withFocusable()(Search);
const CategoryFocusable = withFocusable()(Category);
const ListFocusable = withFocusable()(List);
const VideoFocusable = withFocusable()(Video);
const LoginFocusable = withFocusable()(Login);
const SettingsProfileFocusable = withFocusable()(SettingsProfile);
const SettingsSignOutFocusable = withFocusable()(SettingsSignOut);
const SettingsSubscriptionFocusable = withFocusable()(SettingsSubscription);
const QRLoginFocusable = withFocusable()(QRLogin);
const QRFreeAccountWarningFocusable = withFocusable()(QRFreeAccountWarning);
const QRTimeoutWarningFocusable = withFocusable()(QRTimeoutWarning);
const QRTooManyRequestWarningFocusable = withFocusable()(QRTooManyRequestWarning);
const RedirectToVideoFocusable = withFocusable()(RedirectToVideo);
// const isLoggedIn = async _ => {
//     var userDetails = await getUserDetails();
//     console.log(userDetails !== null);
//     return userDetails !== null;
// }

// if (process.env.NODE_ENV !== 'development') {
// ref: https://docs.sentry.io/platforms/javascript/guides/react/
Sentry.init({
	dsn: "https://9d225f4d039f4931b5ade797b4ca20a9@o1213479.ingest.sentry.io/6352703",
	integrations: [new BrowserTracing()],
	tracesSampleRate: 1.0,
});
// using:
// Sentry.captureMessage(message);
// Sentry.captureException(exception);
// }

setDeviceInformations(); // platform, model, brand gibi bilgileri set eder. uyarı: bazı bilgiler callback ile geldiğinden geç gelebiliyor.

let cookie = new Cookie();
window.dataLayer = window.dataLayer || [];
if (cookie.get('uuid') !== null && cookie.get('uuid') !== undefined) {
	window.dataLayer.push({
		userId: cookie.get('uuid'),
		platform: window.platform !== undefined ? window.platform : "unknown",
		version: window.version !== undefined ? window.version : "unknown",
		brand: window.brand !== undefined ? window.brand : "unknown",
		deviceID: window.deviceID !== undefined ? window.deviceID : "unknown",
		modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}
else {
	window.dataLayer.push({
		platform: window.platform !== undefined ? window.platform : "unknown",
		version: window.version !== undefined ? window.version : "unknown",
		brand: window.brand !== undefined ? window.brand : "unknown",
		deviceID: window.deviceID !== undefined ? window.deviceID : "unknown",
		modelName: window.modelName !== undefined ? window.modelName : "unknown"
	});
}

ReactDOM.render(
	
	<React.Fragment>
		{/* <Router> */} 
		<HashRouter>
			<Routes>
				<Route path="/login" element={<LoginFocusable />} />
				<Route path="/qr-login" element={<QRLoginFocusable />} />
				<Route path="/free-account-warning" element={<QRFreeAccountWarningFocusable />} />
				<Route path="/timeout-warning" element={<QRTimeoutWarningFocusable />} />
				<Route path="/too-many-request-warning" element={<QRTooManyRequestWarningFocusable />} />
				<Route path="/redirect-to-video" element={<RedirectToVideoFocusable />} />

				<Route
					path="/video"
					element={
						<RequireAuth>
							<VideoFocusable />
						</RequireAuth>
					}
				>
					<Route
						path=":id"
						element={
							<RequireAuth>
								<VideoFocusable />
							</RequireAuth>
						}
					/>
				</Route>

				<Route
					path="/title-detail"
					element={
						<RequireAuth>
							<TitleDetailFocusable />
						</RequireAuth>
					}
				>
					<Route
						path=":id"
						element={
							<RequireAuth>
								<TitleDetailFocusable />
							</RequireAuth>
						}
					/>
				</Route>

				<Route path="/" element={<MainLayoutFocusable focusable={true} />}>
					<Route
						index
						element={
							<RequireAuth>
								<HomeFocusable />
							</RequireAuth>
						}
					/>

					<Route
						path="/category"
						element={
							<RequireAuth>
								<CategoryFocusable />
							</RequireAuth>
						}
					>
						<Route
							path=":name"
							element={
								<RequireAuth>
									<CategoryFocusable />
								</RequireAuth>
							}
						/>
					</Route>

					<Route
						path="/title"
						element={
							<RequireAuth>
								<TitleFocusable />
							</RequireAuth>
						}
					>
						<Route
							path=":id"
							element={
								<RequireAuth>
									<TitleFocusable />
								</RequireAuth>
							}
						/>
					</Route>

					<Route
						path="/search"
						element={
							<RequireAuth>
								<SearchFocusable />
							</RequireAuth>
						}
					/>
					<Route
						path="/list"
						element={
							<RequireAuth>
								<ListFocusable />
							</RequireAuth>
						}
					/>
				</Route>

				<Route
					path="/"
					element={<SettingsLayoutFocusable focusable={true} />}
				>
					<Route
						index
						path="/profile"
						element={
							<RequireAuth>
								<SettingsProfileFocusable />
							</RequireAuth>
						}
					/>
					<Route
						path="/signout"
						element={
							<RequireAuth>
								<SettingsSignOutFocusable />
							</RequireAuth>
						}
					/>
					<Route
						path="/subscription"
						element={
							<RequireAuth>
								<SettingsSubscriptionFocusable />
							</RequireAuth>
						}
					/>
				</Route>

				<Route path="/404" element={<PageNotFound />} />
				{/* <Route path="/account" element={<MainLayout/>}>
                <Route path="/home" element={<Home/>} />
              </Route> */}
			</Routes>
		</HashRouter>
	</React.Fragment>,
	document.getElementById("root")
);
