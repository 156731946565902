import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button/button";
import withDirectionFocusable from "../components/withDirectionFocusable";
import BackKeyHandler from "../components/backKeyHandler";
import { getPlatform } from "../helper/platform";
import {
	pageView
} from "../services/gtm";

const ButtonFocusable = withDirectionFocusable(Button);
const BackKeyHandlerFocusable = withDirectionFocusable(BackKeyHandler);

export default function QRTooManyRequestWarning(props) {
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "QR ile giriş yapmayı çok fazla denediniz ve başarılı olamadınız. ";
        pageView();
		props.setFocus("NormalLogin");
	}, []);

	useEffect(() => {
		function keyDown(e) {
			// console.log(e.keyCode);
			// sanal klavyede enter tuşuna basıldığında bir sonraki alana focuslanması sağlanır.
			if (
				(e.keyCode === 13 || e.keyCode === window.VK_ENTER) &&
				getPlatform().name !== "arcelik"
			) {
			}
		}

		window.addEventListener("keydown", keyDown);
		return () => {
			window.removeEventListener("keydown", keyDown);
		};


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="wrapper qr-login-wrapper result-page">
			{/* <LoadingFullscreen show={loading} /> */}
			{(window.platform === "vestel" ||
				window.platform === "vidaa" ||
				window.platform === "tizen" ||
				window.platform === "webos") && (
					<BackKeyHandlerFocusable // todo
						focusKey={"ExitConfirmationFocusable"}
						backFocus={"Complete"}
					/>
				)}

			<span id="logo-gain"></span>

			<div className="content">
				<div className="qr-login-result-main">
					<div className="inner-text">
						<h3>
						QR ile giriş yapmayı çok fazla denediniz ve başarılı olamadınız. 
						</h3>
						<h3>
						Lütfen "TV Kullarak Giriş Yap" seçeneği kullanarak giriş işleminizi tamamlayın.
						</h3>
						<h4>
							Teknik bir sorun yaşıyorsanız veya yardıma ihtiyacınız varsa, lütfen bize e-posta ile ulaşın: destek@gain.com.tr
						</h4>
					</div>
					<div className="btn-group mt">
						<ButtonFocusable
							focusKey={"NormalLogin"}
							id={"NormalLogin"}
							type="button"
							className="btn btn-medium"
							title={"TV Kullarak Giriş Yap"}
							up={""}
							onBecameFocused={(e) => {
								// document.getElementById("LoginButton").focus();
							}}
								onClick={() => {
								navigate("/login", { replace: true });
							}}
							onEnterPress={() => {
								navigate("/login", { replace: true });
								// props.setFocus("Outlet");
							}}
						/>
						{/* <ButtonFocusable
							focusKey={"Exit"}
							id={"Exit"}
							type="button"
							className="btn btn-medium"
							title={"Uygulamadan Çık"}
							up={""}
							onBecameFocused={(e) => {
								// document.getElementById("LoginButton").focus();
							}}
							onEnterPress={() => {
								signOut();
							}}
							onClick={() => {
								signOut();
							}}
						/> */}
					</div>

					{/* <span className="primary-title absolute-title">
						Teknik bir sorun yaşıyorsanız destek@gain.com.tr adresimize
						ulaşınız.
					</span> */}
				</div>
			</div>
		</div>
	);
}
